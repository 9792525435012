import {
  Container,
  Show,
  Avatar,
  HStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  useToast,
  Center,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import useAppDispatch from '../../redux/hooks/useAppDispatch';
import useAppSelector from '../../redux/hooks/useAppSelector';
import * as userThunk from '../../redux/thunks/user';
import { AsyncState } from '../../types/state';

export default function ProfileUpdate() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const user = useAppSelector((state) => state.user.user);
  const updateUserStatus = useAppSelector(
    (state) => state.user.updateUserStatus
  );

  const formik = useFormik({
    initialValues: {
      display_name: user?.display_name,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      web3_account: user?.web3_account,
    },
    onSubmit: async (values) => {
      try {
        await dispatch(userThunk.updateUser(values)).unwrap();
        toast({
          status: 'success',
          title: 'Successfully updated user profile!',
          position: 'top',
          duration: 3000,
        });
      } catch (e: any) {
        toast({
          status: 'error',
          title: `Error ${e.code}`,
          description: e.msg,
          position: 'top',
          duration: 3000,
        });
      }
    },
  });

  return (
    <Container maxW={'1024px'} p={['0px 0px 50px 0px', '50px 20px 50px 20px']}>
      <Show below={'sm'}>
        <Box p={'20px'} h={'calc(100vh - 250px)'}>
          <HStack gap={'50px'} mb={'20px'}>
            <Avatar
              size={'lg'}
              name={user?.display_name ? user?.display_name : user?.email}
              bgColor={'coap.blue.5'}
              color={'#ffffff'}
            />
            {/* <Button
              bgColor={'coap.blue.5'}
              color={'#ffffff'}
              size={'sm'}
              width={'full'}
              _hover={{
                bgColor: 'coap.blue.5',
              }}
              _active={{
                bgColor: 'coap.blue.5',
              }}
            >
              Upload Photo
            </Button> */}
          </HStack>
          <form onSubmit={formik.handleSubmit}>
            <FormControl mb={'10px'}>
              <FormLabel
                htmlFor={'display_name'}
                fontSize={'sm'}
                fontWeight={'semibold'}
              >
                Display name
              </FormLabel>
              <Input
                size={'sm'}
                id={'display_name'}
                name={'display_name'}
                value={formik.values.display_name}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mb={'10px'}>
              <FormLabel
                htmlFor={'first_name'}
                fontSize={'sm'}
                fontWeight={'semibold'}
              >
                Firstname
              </FormLabel>
              <Input
                size={'sm'}
                id={'first_name'}
                name={'first_name'}
                value={formik.values.first_name}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mb={'10px'}>
              <FormLabel
                htmlFor={'last_name'}
                fontSize={'sm'}
                fontWeight={'semibold'}
              >
                Lastname
              </FormLabel>
              <Input
                size={'sm'}
                id={'last_name'}
                name={'last_name'}
                value={formik.values.last_name}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mb={'10px'}>
              <FormLabel
                htmlFor={'email'}
                fontSize={'sm'}
                fontWeight={'semibold'}
              >
                Email address
              </FormLabel>
              <Input
                size={'sm'}
                id={'email'}
                name={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={true}
              />
            </FormControl>
            <FormControl mb={'10px'}>
              <FormLabel
                htmlFor={'web3_account'}
                fontSize={'sm'}
                fontWeight={'semibold'}
              >
                Web3 account
              </FormLabel>
              <Input
                size={'sm'}
                id={'web3_account'}
                name={'web3_account'}
                value={formik.values.web3_account}
                onChange={formik.handleChange}
                disabled={true}
              />
            </FormControl>
            <Button
              bgColor={'coap.blue.5'}
              color={'#ffffff'}
              size={'sm'}
              type={'submit'}
              width={'full'}
              _hover={{
                bgColor: 'coap.blue.5',
              }}
              _active={{
                bgColor: 'coap.blue.5',
              }}
              isLoading={updateUserStatus === AsyncState.PENDING}
              disabled={!formik.dirty && !formik.isSubmitting}
            >
              Update
            </Button>
          </form>
        </Box>
      </Show>

      <Show above={'sm'}>
        <HStack alignItems={'start'}>
          <Center w={'300px'}>
            <Box>
              <Avatar
                name={user?.display_name ? user?.display_name : user?.email}
                width={'150px'}
                height={'150px'}
                borderRadius={'10px'}
                bgColor={'coap.blue.5'}
                color={'#ffffff'}
                mb={'10px'}
              />
              {/* <Button
                display={'block'}
                bgColor={'coap.blue.5'}
                color={'#ffffff'}
                size={'sm'}
                width={'full'}
                _hover={{
                  bgColor: 'coap.blue.5',
                }}
                _active={{
                  bgColor: 'coap.blue.5',
                }}
              >
                Upload Photo
              </Button> */}
            </Box>
          </Center>
          <Box flex={1}>
            <Text fontSize={'2xl'} fontWeight={'semibold'} mb={'20px'}>
              Update Profile
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <FormControl mb={'10px'}>
                <FormLabel
                  htmlFor={'display_name'}
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                >
                  Display name
                </FormLabel>
                <Input
                  size={'sm'}
                  id={'display_name'}
                  name={'display_name'}
                  value={formik.values.display_name}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl mb={'10px'}>
                <FormLabel
                  htmlFor={'first_name'}
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                >
                  Firstname
                </FormLabel>
                <Input
                  size={'sm'}
                  id={'first_name'}
                  name={'first_name'}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl mb={'10px'}>
                <FormLabel
                  htmlFor={'last_name'}
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                >
                  Lastname
                </FormLabel>
                <Input
                  size={'sm'}
                  id={'last_name'}
                  name={'last_name'}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl mb={'10px'}>
                <FormLabel
                  htmlFor={'email'}
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                >
                  Email address
                </FormLabel>
                <Input
                  size={'sm'}
                  id={'email'}
                  name={'email'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  disabled={true}
                />
              </FormControl>
              <FormControl mb={'10px'}>
                <FormLabel
                  htmlFor={'web3_account'}
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                >
                  Web3 account
                </FormLabel>
                <Input
                  size={'sm'}
                  id={'web3_account'}
                  name={'web3_account'}
                  value={formik.values.web3_account}
                  onChange={formik.handleChange}
                  disabled={true}
                />
              </FormControl>
              <Button
                bgColor={'coap.blue.5'}
                color={'#ffffff'}
                size={'sm'}
                type={'submit'}
                _hover={{
                  bgColor: 'coap.blue.5',
                }}
                _active={{
                  bgColor: 'coap.blue.5',
                }}
                isLoading={updateUserStatus === AsyncState.PENDING}
                disabled={!formik.dirty && !formik.isSubmitting}
              >
                Update
              </Button>
            </form>
          </Box>
        </HStack>
      </Show>
    </Container>
  );
}
