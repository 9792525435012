import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraBaseProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import theme from './theme';
import config from './config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import eruda from 'eruda';

if (config.env === 'development') {
  eruda.init();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ChakraBaseProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ChakraBaseProvider>
  </BrowserRouter>
);

reportWebVitals();
